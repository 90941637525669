module.exports = {
  locales: ['en', 'ru'],
  defaultLocale: 'ru',
  pages: {
    '*': ['common'],
    '/marketplace/category/[[...slug]]': ['common'],
    '/': ['home', 'example'],
    '/about': ['about'],
  },
};
