import clone from 'just-clone';
import { fixUrl } from "./fixUrl";
import { FileItem } from "shared/types";
import {
  fileCategory,
  FileCategory,
} from "shared/constants";

export type WithSrc<T> = T & {
  src: string;
}

const NEXT_PUBLIC_AUD = process.env.NEXT_PUBLIC_AUD;
const NEXT_PUBLIC_IMG = process.env.NEXT_PUBLIC_IMG;
const NEXT_PUBLIC_DOC = process.env.NEXT_PUBLIC_IMG;
const NEXT_PUBLIC_MED = process.env.NEXT_PUBLIC_MED;
const NEXT_PUBLIC_VID = process.env.NEXT_PUBLIC_VID;

export function setSrc<T extends FileItem>(fileCat: FileCategory, file: T): WithSrc<T>;
export function setSrc<T extends FileItem>(fileCat: FileCategory, file: T[]): WithSrc<T>[];
export function setSrc<T extends FileItem>(fileCat: FileCategory, file: T|T[]): WithSrc<T>|WithSrc<T>[] {
  if (!fileCat || !file) {
    return <WithSrc<T>>file;
  }
  const fileCl = clone(file);
  const isarr = Array.isArray(fileCl);
  const files = isarr ? <WithSrc<T>[]>fileCl : <WithSrc<T>[]>[fileCl];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file.filepath) {
      if (fileCat === fileCategory.audio) {
        file.src = fixUrl(`${NEXT_PUBLIC_AUD}/${file.filepath}`);
      } else if (fileCat === fileCategory.image) {
        file.src = fixUrl(`${NEXT_PUBLIC_IMG}/${file.filepath}`);
      } else if (fileCat === fileCategory.document) {
        file.src = fixUrl(`${NEXT_PUBLIC_DOC}/${file.filepath}`);
      } else if (fileCat === fileCategory.media) {
        file.src = fixUrl(`${NEXT_PUBLIC_MED}/${file.filepath}`);
      } else if (fileCat === fileCategory.video) {
        file.src = fixUrl(`${NEXT_PUBLIC_VID}/${file.filepath}`);
      }
    }
  }
  return isarr ? files : files[0];
}
