
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { Provider } from 'react-redux';
import { wrapper } from '../shared/lib/toolkit/store/store';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material';
import NextTopLoader from 'nextjs-toploader';
import theme from 'shared/theme';
import 'shared/styles/styles.scss';
import 'shared/styles/fonts/style.css';
import 'react-toastify/dist/ReactToastify.css';
function App({ Component, pageProps: { session, ...pageProps }, }: AppProps) {
    const { store, props } = wrapper.useWrappedStore(pageProps);
    const queryClient = new QueryClient();
    return (<SessionProvider session={session}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <NextTopLoader />
            <Component {...props}/>
            <ToastContainer autoClose={1800} limit={3}/>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </SessionProvider>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  