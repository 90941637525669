// eslint-disable-next-line import/no-anonymous-default-export
export default {
  values: {
    xs: 419,
    sm: 639,
    md: 959,
    lg: 1279,
    xl: 1399,
  },
};
