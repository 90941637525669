import { Lead, ApiError } from 'shared/types';
import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
// import { Company as CompanyType } from '../interfaces/Company';
import { isAxiosError } from 'axios';

export type LeadResponse<T extends Lead | Lead[]> = {
  data: T;
  error: ApiError;
};

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Notification extends BaseEntity {
  protected NOTIFICATIONS: string = 'notifications';

  async getOne(guid: string) {
    console.log('get one lead', guid);
  }

  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.NOTIFICATIONS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data.reverse();
  }
  async readNotifications(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.NOTIFICATIONS, filter);
    return data;
  }
}

export default Notification;
