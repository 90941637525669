// chatQueries.ts
export const getAdverQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getList',
    baseModule: 'Advert',
    data: filter,
  }),
});
// chatQueries.ts
export const getLeadStatusQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getLeadStatus',
    baseModule: 'Leads',
    data: filter,
  }),
});
