import { createApi } from '@reduxjs/toolkit/query/react';
import WebApi from '../../../services';
import { getDictionaryQuery } from './Dictionary';
import { getCategoryQuery } from './Category';
import {
  getConnectsWithUserAndCompany,
  getInviteList,
  getLocationsQuery,
  getPartnersList,
} from './Сompany';
import { getRoleQuery } from './Role';
import { getAdverQuery, getLeadStatusQuery } from './Advert';
import {
  delMessagesQuery,
  getUnReadMessagesQuery,
  getPartnerQuery,
  removeChatUser,
  addUserInChat,
  postMessage,
  getMessages,
  getChatAdvert,
  getRoleList,
  getOrganizationByUser,
  getChat,
  getChatUsers,
  getUsers,
  getLead,
  getLeadsList,
  getChatsList,
  getAdvertCustomerChat,
} from './Chat';
import { getDepartmentList } from './Deportament';
import { getTendersList } from './Tenders';
import { getAdvertsCatalogList, getCatalogList } from './Catalog';

interface BaseQuery {
  functionName?: any;
  baseModule?: any;
  data?: any;
}
export const chatApi = createApi({
  reducerPath: 'chatsApi',
  baseQuery: async ({ functionName, baseModule, data }: BaseQuery) => {
    try {
      const response = await (<any>WebApi())[baseModule][functionName](data);
      return { data: response.data ?? response };
    } catch (error) {
      return { error };
    }
  },
  endpoints: (builder) => ({
    getChatsList: builder.query<any, any>(getChatsList(builder)),
    getAdvertCustomerChat: builder.query<any, any>(
      getAdvertCustomerChat(builder)
    ),
    getLeadsList: builder.query<any, any>(getLeadsList(builder)),
    getLead: builder.query<any, any>(getLead(builder)),
    getUsers: builder.query<any, any>(getUsers(builder)),
    getChatUsers: builder.query<any, any>(getChatUsers(builder)),
    getChat: builder.query<any, string>(getChat(builder)),
    getOrganizationByUser: builder.query<any, any>(
      getOrganizationByUser(builder)
    ),
    getRoleList: builder.query<any, any>(getRoleList(builder)),
    getChatAdvert: builder.query<any, any>(getChatAdvert(builder)),
    getMessages: builder.query<any, string>(getMessages(builder)),
    postMessage: builder.query<any, any>(postMessage(builder)),
    addUserInChat: builder.query<any, any>(addUserInChat(builder)),
    removeUserFromChat: builder.query<any, any>(removeChatUser(builder)),
    getParentChat: builder.query<any, any>(getPartnerQuery(builder)),
    getDictionary: builder.query<any, any>(getDictionaryQuery(builder)),
    getCategory: builder.query<any, any>(getCategoryQuery(builder)),
    getConnectsWithUserAndCompany: builder.query<any, any>(
      getConnectsWithUserAndCompany(builder)
    ),
    getRole: builder.query<any, any>(getRoleQuery(builder)),
    getAdvert: builder.query<any, any>(getAdverQuery(builder)),
    getPartners: builder.query<any, any>(getPartnersList(builder)),
    getDepartment: builder.query<any, any>(getDepartmentList(builder)),
    getTendersList: builder.query<any, any>(getTendersList(builder)),
    getInvetes: builder.query<any, any>(getInviteList(builder)),
    getAdvertsCatalog: builder.query<any, any>(getAdvertsCatalogList(builder)),
    getCatalog: builder.query<any, any>(getCatalogList(builder)),
    getUnReadMessages: builder.query<any, any>(getUnReadMessagesQuery(builder)),
    delUnReadMessages: builder.query<any, any>(delMessagesQuery(builder)),
    getLocations: builder.query<any, any>(getLocationsQuery(builder)),
    getLeadStatus: builder.query<any, any>(getLeadStatusQuery(builder)),
  }),
});

export const {
  useGetChatsListQuery,
  useGetLeadsListQuery,
  useGetInvetesQuery,
  useLazyGetInvetesQuery,
  useLazyGetAdvertCustomerChatQuery,
  useGetLeadQuery,
  useGetPartnersQuery,
  useGetTendersListQuery,
  useGetLocationsQuery,
  useGetUnReadMessagesQuery,
  useLazyGetTendersListQuery,
  useLazyGetPartnersQuery,
  useGetUsersQuery,
  useGetDictionaryQuery,
  useGetCategoryQuery,
  useLazyGetUsersQuery,
  useGetChatUsersQuery,
  useGetLeadStatusQuery,
  useLazyGetChatUsersQuery,
  useLazyGetLeadsListQuery,
  useLazyGetChatsListQuery,
  useGetChatQuery,
  useGetAdvertQuery,
  useLazyGetChatQuery,
  usePostMessageQuery,
  useLazyPostMessageQuery,
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
  useLazyGetChatAdvertQuery,
  useLazyAddUserInChatQuery,
  useLazyDelUnReadMessagesQuery,
  useGetRoleQuery,
  useGetRoleListQuery,
  useGetConnectsWithUserAndCompanyQuery,
  useGetOrganizationByUserQuery,
  useLazyRemoveUserFromChatQuery,
  useGetParentChatQuery,
  useLazyGetParentChatQuery,
  useGetDepartmentQuery,
  useLazyGetDepartmentQuery,
  useGetAdvertsCatalogQuery,
  useGetCatalogQuery,
  useLazyGetCatalogQuery,
} = chatApi;
