import {
  FileExt,
  AudioExt,
  ImageExt,
  VideoExt,
  MediaExt,
  audioExts,
  imageExts,
  videoExts,
  mediaExts,
  DocumentExt,
  fileCategory,
  FileCategory,
  documentExts,
} from '../constants/File';

export function getFileCategoryByExt(val: FileExt): FileCategory|void {
  switch (true) {
    case audioExts.includes(<AudioExt>val):
      return fileCategory.audio;
    case documentExts.includes(<DocumentExt>val):
      return fileCategory.document;
    case imageExts.includes(<ImageExt>val):
      return fileCategory.image;
    case mediaExts.includes(<MediaExt>val):
      return fileCategory.media;
    case videoExts.includes(<VideoExt>val):
      return fileCategory.video;
  }
}

export function isImageExt(val: ImageExt): val is ImageExt {
  return imageExts.includes(val);
}

export function isDocumentExt(val: DocumentExt): val is DocumentExt {
  return documentExts.includes(val);
}

export function isAudioExt(val: AudioExt): val is AudioExt {
  return audioExts.includes(val);
}

export function isVideoExt(val: VideoExt): val is VideoExt {
  return videoExts.includes(val);
}

export function isMediaExt(val: MediaExt): val is MediaExt {
  return mediaExts.includes(val);
}
