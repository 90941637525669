export function isObj<T = never>(
  val: unknown
): val is typeof val extends T
  ? T
  : typeof val extends object
  ? object
  : typeof val extends Object
  ? Object
  : typeof val extends {}
  ? {}
  : T {
  return {}.toString.call(val).slice(8, -1).toLowerCase() === 'object';
}
