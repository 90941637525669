import {isStr} from "./isStr";

export function fixUrl(url: string): string;
export function fixUrl(url: string[]): string[];
export function fixUrl(url: string|string[]): string|string[] {
  if (isStr(url)) {
    return url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
  } else if (Array.isArray(url)) {
    return url.map(v=> v.replace(/(https?:\/\/)|(\/)+/g, "$1$2"));
  }
  return url;
}
