import { Entity } from 'shared/types';
import { defined } from './defined';

export type GetParams<T extends Entity> = Record<keyof T|string, T[keyof T]|T[keyof T][]>;

export type KeyParam<T> = {
  sourceKey: keyof T;
  param: keyof T|string;
  value?: T[keyof T]|T[keyof T][];
}

export type GetParamsArgs<T> = {
  data: T|T[];
  keyParam: KeyParam<T>|KeyParam<T>[];
}

export function getParams<T extends Entity = Entity>(
  args: GetParamsArgs<T>
): GetParams<T> {
  const {data, keyParam} = args;
  const params = <GetParams<T>>{};
  const isarr = Array.isArray(data);
  const dataArr = isarr ? data : [data];
  const keyParamArr = Array.isArray(keyParam) ? keyParam : [keyParam];
  for (let i = 0; i < keyParamArr.length; i++) {
    const key = keyParamArr[i].sourceKey;
    const param = keyParamArr[i].param;
    const value = keyParamArr[i].value;
    for (let j = 0; j < dataArr.length; j++) {
      const data = dataArr[j];
      if (data[key]) {
        if (isarr && !defined(params[param])) {
          params[param] = [];
          if (Array.isArray(value)) {
            (<T[keyof T][]>params[param]).push(...value);
          } else if (defined(value)) {
            (<T[keyof T][]>params[param]).push(<T[keyof T]>value);
          }
        }
        if (isarr) {
          (<T[keyof T][]>params[param]).push(<T[keyof T]>data[key]);
        } else {
          params[param] = defined(value) ? <T[keyof T]>value : data[key];
        }
      }
    }
  }
  return params;
}
