import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class SMS extends BaseEntity {
  protected API_SLUG: string = 'sms';
  protected API_SIGNIN = 'signin';
  protected API_RESET: string = 'reset-pass';

  async sendSms(params?: any) {
    const { err, data } = await this.patch(this.API_SLUG, params);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }
  async resetPass(params?: any) {
    const { err, data } = await this.post(this.API_SIGNIN, {
      phone: params.phoneNumber,
      code: params.code,
    });
    const access = data?.data?.data?.access;
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    delete params.phoneNumber;
    const { err: _err, data: _data } = await this.post(this.API_RESET, params, {
      headers: { authorization: access },
    });
    if (_err)
      return { err: { message: (_err as any).response.data.error.message } };
    if (_data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { _data };
  }
}

export default SMS;
