export const errorCode = {
  alreadyExists: 'ALREADY_EXISTS',
  fileNotValid: 'FILE_NOT_VALID',
  fileNotSupported: 'FILE_NOT_SUPPORTED',
  fstFilesLimit: 'FST_FILES_LIMIT',
  innNotValid: 'INN_NOT_VALID',
  emailNotValid: 'EMAIL_NOT_VALID',
} as const;

export type ErrorCode = (typeof errorCode)[keyof typeof errorCode];

export const errorMessage = {
  error: 'Ошибка!',
  alreadyExists: 'Уже существует!',
  leadExists: 'Заявка уже подана!',
  formatNotSupported: 'Формат не поддерживается!',
  fileNotValid: 'Файл не валидный!',
  fstFilesLimit: 'Превышен лимит количества загружаемых файлов!',
  innNotValid: 'ИНН не валидный!',
  emailNotValid: 'Email не валидный!',
} as const;

export type ErrorMessage = (typeof errorMessage)[keyof typeof errorMessage];

export function getErrorMessage(code: ErrorCode): ErrorMessage {
  switch (true) {
    case code === errorCode.alreadyExists:
      return errorMessage.alreadyExists;
    case code === errorCode.fileNotValid:
      return errorMessage.fileNotValid;
    case code === errorCode.fileNotSupported:
      return errorMessage.formatNotSupported;
    case code === errorCode.fstFilesLimit:
      return errorMessage.fstFilesLimit;
    case code === errorCode.innNotValid:
      return errorMessage.innNotValid;
    case code === errorCode.emailNotValid:
      return errorMessage.emailNotValid;
  }
  return errorMessage.error;
}
