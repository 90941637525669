export * from './addFiles';
export * from './defined';
export * from './file';
export * from './fixUrl';
export * from './getFileTitle';
export * from './getQuery';
export * from './getParams';
export * from './getSrc';
export * from './isError';
export * from './isObj';
export * from './isStr';
export * from './setRouterQueryParams';
export * from './setSrc';

export const capitalizeFirstLetter = (str: string) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
