// import { Advert as AdvertInterface } from '../interfaces/Advert';
import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import WebApi from '..';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Tender extends BaseEntity {
  protected TENDERS: string = 'tenders';

  async getOne(params?: any) {
    const { err, data } = await this.get(this.TENDERS, params);
    if (err) return { err };
    if (data?.category?.id) {
      const { err: _err, data: _data } = await this.get(this.TENDERS, {
        ...params,
        category: data?.category?.alias,
      });
      if (_err) return { err: _err };
      return { data: _data };
    }
  }

  async getList(filter: {}) {
    const { err, data } = await this.get(this.TENDERS, filter);
    return data;
  }

  async create(params?: any) {
    params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.post(this.TENDERS, params);
    if (err)
      return { err: { message: (err as any).response?.data?.error?.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async change(
    params?: any
  ): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.put(this.TENDERS, params);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async removeOne(params?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.TENDERS, params);
    if (err) {
      return { err: { message: (err as any).response.data.error.message } };
    }
    return { data };
  }
}

export default Tender;
