import { Advert as AdvertInterface } from '../interfaces/Advert';
import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import WebApi from '..';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Advert extends BaseEntity {
  protected ADVERTS: string = 'adverts';
  protected LOCATIONS: string = 'locations';

  async getOne(params?: any) {
    const { err, data } = await this.get(this.ADVERTS, params);
    if (err) return { err };
    if (data?.category?.id) {
      const { err: _err, data: _data } = await this.get(this.ADVERTS, {
        ...params,
        category: data?.category?.alias,
      });
      if (_err) return { err: _err };
      const { err: _errWithLoc, data: _dataWithLoc } = await this.get(
        this.LOCATIONS,
        { advertId: data.id }
      );
      return {
        data: {
          ..._data,
          point:
            _dataWithLoc && _dataWithLoc?.length && _dataWithLoc[0]?.point
              ? _dataWithLoc[0]?.point
              : { coordinates: [] },
        },
      };
    }
  }

  async getList(filter: {}) {
    const { err, data: adverts } = await this.get(this.ADVERTS, filter);
    const newAdvertsList = [];
    if (adverts?.adverts) {
      for (let index = 0; index < adverts.adverts.length; index++) {
        const { err: _errWithLoc, data: _dataWithLoc } = await this.get(
          this.LOCATIONS,
          { advertId: adverts.adverts[index].id }
        );
        if (_dataWithLoc?.length && _dataWithLoc[0]?.point) {
          newAdvertsList.push({
            ...adverts.adverts[index],
            point: _dataWithLoc[0].point,
          });
        } else {
          newAdvertsList.push(adverts.adverts[index]);
        }
      }
    }
    const data = {
      ...adverts,
      adverts: newAdvertsList.length
        ? newAdvertsList
        : adverts?.adverts
        ? adverts?.adverts
        : adverts,
    };
    return data;
  }

  async getSubAdverts(filter: {}) {
    const { err, data } = await this.get(this.ADVERTS, filter);
    const organizationIds =
      data?.adverts?.map((i: any) => i.organization.id) ?? [];
    const { err: _err, data: _data } = await this.get(this.ADVERTS, {
      organizationIds,
      group: 'organization',
      groupLimit: 4,
      advertStatus: 'published',
    });
    data?.adverts?.map(
      (i: any) =>
        (i.sub = _data
          .filter(
            (sub: any) =>
              sub.organizationId === i.organizationId && sub.id !== i.id
          )
          .slice(0, 3))
    );
    return data;
  }

  async create(
    params?: any
  ): Promise<
    WebApiResponse<{ data: { data: AdvertInterface; error: Error } }>
  > {
    params.userId = (await this._getSession())?.user?.id;
    params.responsibleId = (await this._getSession())?.user?.id;
    const { err, data } = await this.post(this.ADVERTS, {
      ...params,
      address:
        params.address.GeoObject?.metaDataProperty.GeocoderMetaData.Address
          .formatted,
    });
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    await this.post(this.LOCATIONS, {
      point: {
        type: 'Point',
        coordinates: params.address.GeoObject.Point.pos.split(' '),
      },
      userId: data.data.data.userId,
      advertId: data.data.data.id,
    });
    return { data };
  }

  async change(
    params?: any
  ): Promise<
    WebApiResponse<{ data: { data: AdvertInterface; error: Error } }>
  > {
    const { err, data } = await this.put(this.ADVERTS, {
      ...params,
      address:
        typeof params.address === 'string'
          ? params.address
          : params.address.GeoObject?.metaDataProperty.GeocoderMetaData.Address
              .formatted,
    });
    params.userId = (await this._getSession())?.user?.id;
    params.responsibleId = (await this._getSession())?.user?.id;
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    if (params?.address?.GeoObject?.Point?.pos) {
      const { err: _err, data: location } = await this.get(this.LOCATIONS, {
        advertId: data.data.data.id,
      });
      await this.put(this.LOCATIONS, {
        id: location[0].id,
        point: {
          type: 'Point',
          coordinates: params.address.GeoObject.Point.pos.split(' '),
        },
        userId: data.data.data.userId,
        advertId: data.data.data.id,
      });
    }
    return { data };
  }

  async changeStatus({
    advertId,
    advertStatus,
  }: {
    advertId: number;
    advertStatus: 'published' | 'withdrawn' | 'draft' | 'inCatalog' | 'archive';
  }): Promise<WebApiResponse<AdvertInterface>> {
    const { advertStatus: advertStatusList } = await WebApi().Dictionary.getOne(
      { dicts: 'advertStatus' }
    );
    const { id: statusId } = advertStatusList.find(
      (item: any) => item.alias === advertStatus
    );
    const { err, data } = await this.put(this.ADVERTS, {
      id: advertId,
      advertStatusId: statusId,
    });
    if (err)
      return { err: { message: (err as any).response?.data?.error?.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async removeOne(params?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.ADVERTS, params);
    if (err) {
      return { err: { message: (err as any).response.data.error.message } };
    }
    return { data };
  }
}

export default Advert;
