export const getConnectsWithUserAndCompany = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getRoleList',
    baseModule: 'Company',
    data: filter,
  }),
});

export const getPartnersList = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getPartners',
    baseModule: 'Company',
    data: filter,
  }),
});
export const getInviteList = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getInvite',
    baseModule: 'Company',
    data: filter,
  }),
});
// chatQueries.ts
export const getLocationsQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getLocation',
    baseModule: 'Company',
    data: filter,
  }),
});
