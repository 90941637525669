import { isObj } from "./isObj";
import { isStr } from "./isStr";
import { fixUrl } from "./fixUrl";
import { FileItem } from "shared/types";
import {
  fileCategory,
  FileCategory,
} from 'shared/constants';

const NEXT_PUBLIC_AUD = process.env.NEXT_PUBLIC_AUD;
const NEXT_PUBLIC_IMG = process.env.NEXT_PUBLIC_IMG;
const NEXT_PUBLIC_DOC = process.env.NEXT_PUBLIC_IMG;
const NEXT_PUBLIC_MED = process.env.NEXT_PUBLIC_MED;
const NEXT_PUBLIC_VID = process.env.NEXT_PUBLIC_VID;

export function getSrc(fileCat: FileCategory, file: FileItem|string): string;
export function getSrc(fileCat: FileCategory, file: FileItem[]|string[]): string[];
export function getSrc(fileCat: FileCategory, file: FileItem|FileItem[]|string|string[]): string|string[] {
  if (!file) {
    return file;
  }
  let src: string[] = [];
  if (Array.isArray(file)) {
    for (let i = 0; i < file.length; i++) {
      const fl = file[i];
      if (isObj<FileItem>(fl)) {
        src.push(fl.filepath);
      } else if (isStr(fl)) {
        src.push(fl)
      }
    }
  } else if (isObj<FileItem>(file)) {
    src.push(file.filepath);
  } else if (isStr(file)) {
    src.push(file)
  }
  if (fileCat === fileCategory.audio) {
    src = src.map(v => fixUrl(`${NEXT_PUBLIC_AUD}/${v}`));
  } else if (fileCat === fileCategory.image) {
    src = src.map(v => fixUrl(`${NEXT_PUBLIC_IMG}/${v}`));
  } else if (fileCat === fileCategory.document) {
    src = src.map(v => fixUrl(`${NEXT_PUBLIC_DOC}/${v}`));
  } else if (fileCat === fileCategory.media) {
    src = src.map(v => fixUrl(`${NEXT_PUBLIC_MED}/${v}`));
  } else if (fileCat === fileCategory.video) {
    src = src.map(v => fixUrl(`${NEXT_PUBLIC_VID}/${v}`));
  }
  if (Array.isArray(file)) {
    return src;
  } else {
    return src[0];
  }
}
