import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import { Company as CompanyType } from '../interfaces/Company';
import WebApi from '..';
import { ApiError } from 'types/ApiError';

export type LeadResponse<T extends CompanyType> = {
  data: T;
  error: ApiError;
};

interface AddressType {
  GeoObject?: {
    metaDataProperty?: {
      GeocoderMetaData?: {
        Address?: {
          formatted?: string;
        };
      };
    };
  };
}

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Company extends BaseEntity {
  protected API_SLUG: string = 'organizations';
  protected API_ROLE: string = 'organization-user-roles';
  protected API_EMPLOYEE: string = 'organization-joins';
  protected API_CATEGORIES: string = 'organization-categories';
  protected API_DEPARTMENTS: string = 'organization-departments';
  protected API_PARTNERS: string = 'organization-relations';
  protected API_INVITE = 'invites';
  protected LOCATIONS: string = 'locations';
  protected API_ORGTYPES: string = 'organization-types';

  async getOne(params?: any) {
    const { err, data } = await this.get(this.API_SLUG, params);
    if (err) return [];
    return { data };
  }
  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_SLUG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async getLocation(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.LOCATIONS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async postConnectEmployee(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.API_ROLE, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async deleteConnectEmployee(filter: {}): Promise<any> {
    const { err, data } = await this.del(this.API_ROLE, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async getRoleList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_ROLE, filter);
    return data;
  }
  async getCode(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.API_EMPLOYEE, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async getType(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_ORGTYPES, filter);
    if (err)
      return {
        err: {
          message:
            (err as any)?.response?.data?.error?.message ||
            'Неизвестная ошибка',
        },
      };
    return data;
  }
  async joinCompany(code: string): Promise<any> {
    const { err, data } = await this.get(`${this.API_EMPLOYEE}/${code}`, {});
    if (err)
      return {
        err: {
          organizationId: (err as any).response.data.error.data.organizationId,
        },
      };
    return { data };
  }
  async createCategoryCompany(filter: {}): Promise<any> {
    const { err, data } = await this.post(`${this.API_CATEGORIES}`, filter);
    if (err)
      return {
        err: {
          message:
            (err as any)?.response?.data?.error?.message ||
            'Неизвестная ошибка',
        },
      };
    return data;
  }
  async changeCategoryCompany(filter: {}): Promise<any> {
    const { err, data } = await this.patch(`${this.API_CATEGORIES}`, filter);
    if (err)
      return {
        err: {
          message:
            (err as any)?.response?.data?.error?.message ||
            'Неизвестная ошибка',
        },
      };
    return data;
  }
  async getCategoryCompany(filter: {}): Promise<any> {
    const { err, data } = await this.get(`${this.API_CATEGORIES}`, filter);
    if (err)
      return {
        err: {
          message:
            (err as any)?.response?.data?.error?.message ||
            'Неизвестная ошибка',
        },
      };
    return data;
  }
  async create<T extends CompanyType>(
    params: Partial<CompanyType>
  ): Promise<LeadResponse<T>> {
    const { err, data: respData } = await this.post(this.API_SLUG, {
      ...params,
      address:
        typeof params.address === 'string'
          ? params.address
          : (params.address as unknown as AddressType)?.GeoObject
              ?.metaDataProperty?.GeocoderMetaData?.Address?.formatted,
    });
    const data: T = respData?.data?.data;
    const error: ApiError = (<any>err)?.response?.data?.error;
    return { data, error };
    // await this.post(this.LOCATIONS, {
    //   point: {
    //     type: 'Point',
    //     coordinates: params.address.GeoObject.Point.pos.split(' '),
    //   },
    //   userId: data.data.data.userId,
    //   advertId: data.data.data.id,
    // });
  }
  async change<T extends CompanyType>(
    params: Partial<CompanyType>
  ): Promise<LeadResponse<T>> {
    const { err, data: respData } = await this.put(this.API_SLUG, {
      ...params,
      address:
        typeof params.address === 'string'
          ? params.address
          : (params.address as unknown as AddressType)?.GeoObject
              ?.metaDataProperty?.GeocoderMetaData?.Address?.formatted,
    });

    const data: T = respData?.data?.data;
    const error: ApiError = (<any>err)?.response?.data?.error;
    return { data, error };
  }

  async changeStatus({
    companyId,
    organizationStatus,
  }: {
    companyId: number;
    organizationStatus:
      | 'draft'
      | 'review'
      | 'verified'
      | 'rejected'
      | 'closed'
      | 'created'
      | 'blocked';
  }): Promise<WebApiResponse<CompanyType>> {
    const { organizationStatus: organizationStatusList } =
      await WebApi().Dictionary.getOne({ dicts: 'organizationStatus' });
    const { id: statusId } = organizationStatusList.find(
      (item: any) => item.alias === organizationStatus
    );
    const { err, data } = await this.put(this.API_SLUG, {
      id: companyId,
      organizationStatusId: statusId,
    });
    if (err)
      return { err: { message: (err as any).response?.data?.error?.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }
  async removeOne(params?: any) {
    this.del(this.API_SLUG, {});
  }
  async getDepartmentsList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_DEPARTMENTS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async postDepartment(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.API_DEPARTMENTS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async putDepartment(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.API_DEPARTMENTS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async delDepartment(filter: {}): Promise<any> {
    const { err, data } = await this.del(this.API_DEPARTMENTS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return { data };
  }
  async getPartners(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_PARTNERS, filter);

    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return { data: data };
  }
  async getInvite(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_INVITE, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return { data: data };
  }
  async postInvite(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.API_INVITE, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return { data };
  }
  async putPartners(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.API_INVITE, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return { data };
  }
  async postNoPartners(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.API_PARTNERS, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return { data };
  }
  async updatePartners(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.API_PARTNERS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return { data };
  }
  async delPartners(filter: {}): Promise<any> {
    const { err, data } = await this.del(this.API_PARTNERS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return { data };
  }
}

export default Company;
