import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Project extends BaseEntity {
  protected API_SLUG: string = 'projects';

  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_SLUG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }

  async create(filter: {}) {
    const { err, data } = await this.post(this.API_SLUG, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async change(filter: {}) {
    const { err, data } = await this.post(this.API_SLUG, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }
}

export default Project;
