import {isObj} from './isObj';

export function getQuery(params: unknown): string {
  if (!params || !isObj(params)) {
    return '';
  }
  const keys = <(keyof typeof params)[]>Object.keys(params);
  if (!keys.length) {
    return '';
  }
  const search = new URLSearchParams();
  for (let i = 0; i < keys.length; i++) {
    const key = <string>keys[i];
    const val: unknown|unknown[] = params[key];
    if (Array.isArray(val)) {
      for (let j = 0; j < val.length; j++) {
        search.append(key, String(val[j]));
      }
    } else {
      search.append(key, String(val));
    }
  }
  return search.toString();
}
