export const getAdvertsCatalogList = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getAdvertsCatalog',
    baseModule: 'Catalog',
    data: filter,
  }),
});

export const getCatalogList = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getList',
    baseModule: 'Catalog',
    data: filter,
  }),
});
