import { WebApiResponse } from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class User extends BaseEntity {
  protected SIGNIN: string = 'signin';
  protected SIGNUP: string = 'signup';
  protected SIGNOUT: string = 'signout';
  protected GETUSER: string = 'users/my';
  protected CHANGE: string = 'users';
  protected GETUSERLIST: string = 'users';
  protected RESETPASSWORD: string = 'reset-pass';
  protected API_CATEGORIES: string = 'user-categories';

  async removeToken(params?: any) {
    const { err, data } = await this.get(this.SIGNOUT, params);
    if (err) return { err };
    return { data };
  }
  async getUser(params?: any) {
    const { err, data } = await this.get(this.GETUSER, params);
    if (err) return { err };
    return { data };
  }
  async getList(params?: any) {
    const { err, data } = await this.get(this.GETUSERLIST, params);
    if (err) return { err };
    return { data };
  }

  async change(params?: any) {
    const { err, data } = await this.put(this.CHANGE, params);
    params.userId = (await this._getSession())?.user?.id;
    if (err)
      return { err: { message: (err as any).response?.data?.error?.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async signin(params?: any) {
    const { err, data } = await this.post(this.SIGNIN, params);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async signup(params?: any) {
    const { err, data } = await this.post(this.SIGNUP, params);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async resetPassword(params?: any) {
    const { err, data } = await this.post(this.RESETPASSWORD, params);
    if (err) return { err };
    return { data };
  }
  async changeUserCategory(filter: {}): Promise<any> {
    const { err, data } = await this.patch(`${this.API_CATEGORIES}`, filter);
    if (err)
      return {
        err: {
          message:
            (err as any)?.response?.data?.error?.message ||
            'Неизвестная ошибка',
        },
      };
    return data;
  }
  async getUserCategory(filter: {}): Promise<any> {
    const { err, data } = await this.get(`${this.API_CATEGORIES}`, filter);
    if (err)
      return {
        err: {
          message:
            (err as any)?.response?.data?.error?.message ||
            'Неизвестная ошибка',
        },
      };
    return data;
  }
}

export default User;
