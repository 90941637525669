import { WebApiMethods, WebApiRequest } from 'shared/types/WebApiTypes';
import { Session } from 'next-auth';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
Класс содержит методы:

this.post
this.get
this.put
this.delete
this._getSession() для получения текущей сессии пользователя

 */
class BaseEntity {
  protected post: WebApiRequest<any>;
  protected get: WebApiRequest<any>;
  protected put: WebApiRequest<any>;
  protected del: WebApiRequest<any>;
  protected patch: WebApiRequest<any>;
  protected _getSession: () => Promise<Session | null>;

  constructor({ _post, _get, _put, _delete, _getSession, _patch }: WebApiMethods) {
    this.post = _post;
    this.get = _get;
    this.put = _put;
    this.del = _delete;
    this.patch = _patch;
    this._getSession = _getSession;
  }
}

export default BaseEntity;
