import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Roles extends BaseEntity {
  protected API_ROLES: string = 'roles';

  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_ROLES, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
}

export default Roles;
