import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-tree-view/themeAugmentation';

import palette from './palette';
import breakpoints from './breakpoints';

export default createTheme({
  palette,
  breakpoints,
  typography: {
    fontFamily: '"Rubik", sans-serif',

    allVariants: {
      fontWeight: 400,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          // border: '1px solid red',
          // zIndex: 9999,
          // background: '#18181B',
          // color: 'red',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          marginTop: '5px',
          marginBottom: '5px',
          borderRadius: '6px',
          padding: '3px 2px',

          background: 'var(--neutral-100, #F4F4F5)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginTop: 16,
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16,
          color: '#19191D',
          '& .Mui-expanded': {
            minHeight: 10,
            background: '#F7F7FA',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: 1114,
          maxHeight: 716,
          marginTop: 100,
          padding: '24px 16px',
          background: '#ffffff',
          'box-shadow':
            ' 0px 1px 2px rgba(58, 58, 68, 0.24),' +
            '0px 2px 4px rgba(90, 91, 106, 0.24)',
          borderRadius: '24px 24px 24px 24px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          border: 'none',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '143%',
          letterSpacing: '0.018em',
          color: '#5A5B6A',
          height: 36,
          background: '#EBF2FF',
          borderRadius: 4,
          '&.Mui-selected, &.Mui-selected:hover': {
            background: '#FFFFFF',
            boxShadow:
              '0px 1px 2px rgba(27, 78, 163, 0.24), 0px 2px 4px rgba(41, 121, 255, 0.24)',
            borderRadius: 4,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: 'auto',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          background: '#F7F7FA',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18,
          marginLeft: 19,
          marginRight: 19,
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          marginTop: 11,
          fontSize: 16,
          fontWeight: 400,
          color: '#19191D',
          textTransform: 'capitalize',
          mb: 1.5,
          p: 2,
          '& > .MuiTreeItem-content.Mui-selected': {
            color: palette.grey[900],
            background: palette.grey[300],
          },
          '& > .MuiTreeItem-content.Mui-focused': {
            color: palette.grey[900],
            background: palette.grey[300],
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          background: '#EBF2FF',
          borderRadius: 2,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // color: '#18181B',
        },
        h5: {
          fontWeight: 400,
        },
        body1: {
          fontSize: '0.8125rem',
          fontWeight: 400,
          fontFamily: 'Rubik',
          lineHeight: '140%',
        },
        body2: {
          fontSize: '0.8125rem',
          fontWeight: 500,
          fontFamily: 'Rubik',
          lineHeight: '140%',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: 'var(--radius-btn, 12px)',
          border: '1px solid var(--neutral-100, #F4F4F5)',
          background: 'var(--neutral-0, #FFF)',
          boxSshadow:
            '0px 9px 9px -7px rgba(0, 0, 0, 0.15), 0px 1px 4px 0px rgba(0, 0, 0, 0.10)',
          padding: '24px 0 28px 29px',
          margin: '8px 0',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 0,
          color: '#FFF',
          backgroundColor: '#18181B',
          cursor: 'pointer',
          '&::first-letter': {
            textTransform: 'uppercase',
          },
          ':hover': {
            background: palette.grey[400],
            color: 'black',
            opacity: 0.5,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          textDecoration: 'none',
          ':hover': {
            color: '#006FEE',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
  },
});
