import { WebApiResponse } from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Catalog extends BaseEntity {
  protected API_SLUG: string = 'catalogs';
  protected API_ADVERTCATALOG: string = 'advert-catalogs';

  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_SLUG, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return data;
  }

  async create(filter: {}) {
    const { err, data } = await this.post(this.API_SLUG, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return data;
  }

  async change(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.put(this.API_SLUG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async postAdvert(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.post(this.API_ADVERTCATALOG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async getAdvertsCatalog(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.get(this.API_ADVERTCATALOG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async delAdvertCatalog(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.del(this.API_ADVERTCATALOG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }

  async removeOne(params?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.API_SLUG, params);
    if (err) {
      return { err: { message: (err as any).response } };
    }
    return data;
  }
}

export default Catalog;
