import clone from 'just-clone';
import WebApi from 'shared/services';
import { Session } from 'next-auth';
import { Entity, ApiError, FileItem } from 'shared/types';
import { setSrc, WithSrc } from './setSrc';
import {
  FileSlug,
  fileSlug,
  FileCategory,
  fileCategories,
} from 'shared/constants';
import { KeyParam, getParams } from './getParams';

export type AddFilesParams<T extends FileItem> = Partial<
  Record<keyof T | string, T[keyof T] | T[keyof T][]>
>;

export type KeysParam<D, F> = KeyParam<D> & {
  targetKey: keyof F;
};

export type AddFilesArgs<
  D extends Entity = Entity,
  F extends FileItem = FileItem,
> = {
  session: Session | null;
  fileSlg: FileSlug;
  data: D | D[];
  params?: AddFilesParams<F>;
  keyParam: KeysParam<D, F> | KeysParam<D, F>[];
  clone?: boolean;
};
export type WithFiles<T> = T & {
  [key in FileSlug]?: WithSrc<FileItem>[];
};

export type EntityWithFile<
  D extends Entity,
  F extends FileItem = FileItem,
> = D & {
  [key in FileSlug]?: WithSrc<F>[];
};

export type AddFilesResult<D extends Entity, F extends FileItem> = Partial<{
  data: EntityWithFile<D, F> | EntityWithFile<D, F>[];
  error: ApiError;
}>;

export async function addFiles<
  D extends Entity = Entity,
  F extends FileItem = FileItem,
>(
  args: Omit<AddFilesArgs<D, F>, 'data'> & { data: D }
): Promise<Omit<AddFilesResult<D, F>, 'data'> & { data: EntityWithFile<D, F> }>;
export async function addFiles<
  D extends Entity = Entity,
  F extends FileItem = FileItem,
>(
  args: Omit<AddFilesArgs<D, F>, 'data'> & { data: D[] }
): Promise<
  Omit<AddFilesResult<D, F>, 'data'> & { data: EntityWithFile<D, F>[] }
>;
export async function addFiles<
  D extends Entity = Entity,
  F extends FileItem = FileItem,
>(args: AddFilesArgs<D, F>): Promise<AddFilesResult<D, F>> {
  const {
    session,
    fileSlg = fileSlug.files,
    data,
    params = {},
    keyParam,
    clone: isClone = true,
  } = args;

  const dataCl = isClone
    ? <EntityWithFile<D, F>>clone(<object>data)
    : <EntityWithFile<D, F>>data;
  if (!data) {
    return { data: dataCl };
  }
  const allParams = <AddFilesParams<F>>{
    ...params,
    ...getParams<D>({ data: dataCl, keyParam }),
  };
  if (!Object.keys(allParams).length) {
    return { data: dataCl };
  }
  const isarr = Array.isArray(dataCl);
  const dataClArr = isarr ? dataCl : [dataCl];
  const result: EntityWithFile<D, F>[] = [];
  const { data: files, error } = await WebApi(session).Files.getFile<F, F[]>(
    fileSlg,
    allParams
  );
  if (error) {
    return { error };
  }
  if (!files?.length) {
    return { data: dataCl };
  }
  const keyParamArr = Array.isArray(keyParam) ? keyParam : [keyParam];
  for (let i = 0; i < dataClArr.length; i++) {
    const dtf = dataClArr[i];
    result.push(dtf);
    j: for (let j = 0; j < files.length; j++) {
      const fct = fileCategories.find((v) =>
        files[j].hasOwnProperty(`${v}TypeId`)
      ); // get file category by patameter name
      const fsc = setSrc<F>(<FileCategory>fct, files[j]);
      let matchDataAndFile = false;
      for (let k = 0; k < keyParamArr.length; k++) {
        const sourceKey = keyParamArr[k].sourceKey;
        const targetKey = keyParamArr[k].targetKey;
        matchDataAndFile =
          dtf[sourceKey] ===
          <EntityWithFile<D, F>[keyof D]>(<unknown>fsc[targetKey]);
        if (!matchDataAndFile) {
          continue j;
        }
      }
      if (matchDataAndFile) {
        dtf[fileSlg] ||= [];
        dtf[fileSlg]!.push(fsc);
      }
    }
  }
  return { data: isarr ? result : result[0] };
}
