import { WebApiResponse } from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';

const BASE_PATH = process.env.NEXT_PUBLIC_APP_URL;

class Image extends BaseEntity {
  protected API_SLUG: string = 'images';
  protected ContentType: string = 'multipart/form-data';

  async getImage(params: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(this.API_SLUG, params);
    if (err) return { err };
    return { data };
  }
  async getAdvertImage(advertId: string): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(this.API_SLUG, {
      advertId,
    });
    if (err) return { err };
    return { data };
  }

  async create(params?: any): Promise<WebApiResponse<any>> {
    params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.post(
      this.API_SLUG,
      params,
      {contentType: this.ContentType}
    );
    if (err) {
      const message = (err as any).response.data.error?.message ||
        (err as any).message;
      return { err: { message }};
    }
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async update(params?: any): Promise<WebApiResponse<any>> {
    params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.put(
      this.API_SLUG,
      params,
      {contentType: this.ContentType}
    );
    if (err) {
      const message = (err as any).response.data.error?.message ||
        (err as any).message;
      return { err: { message }};
    }
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async delImage(params: {id?: number, ids?: number[]}): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.API_SLUG, params);
    if (err) {
      const message = (err as any).response.data.error?.message ||
        (err as any).message;
      return { err: { message }};
    }
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }
}

export default Image;
