import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Dictionary extends BaseEntity {
  protected API_SLUG: string = 'dictionaries';

  async getOne(params?: any) {
    const { data } = await this.get(this.API_SLUG, params);
    return data;
  }
}

export default Dictionary;
