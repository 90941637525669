// eslint-disable-next-line import/no-anonymous-default-export
export default {
  primary: {
    light: '#6C817F',
    main: '#006FEE',
    dark: '#2D4B48',
    contrastText: '#D5DBDA',
    50: '#F4F4F5',
    100: '#EAEDED',
    200: '#D5DBDA',
    300: '#C0C9C8',
    400: '#ABB7B6',
    500: '#18181B',
    600: '#6C817F',
    700: '#576F6D',
    800: '#006FEE',
    900: '#2D4B48',
  },
  secondary: {
    light: '#FFFFFF',
    main: '#FCE000',
    dark: '#FCB11C',
    contrastText: '#FDD077',
    50: '#FFFBF4',
    100: '#FFF7E8',
    200: '#FEEFD2',
    300: '#FEE8BB',
    400: '#FEE0A4',
    500: '#FDD077',
    600: '#FDC860',
    700: '#FDC149',
    800: '#FCB933',
    900: '#FCB11C',
  },
  success: {
    main: '#00BA3E',
  },

  grey: {
    50: '#E6F1FE',
    100: '#F4F4F5',
    200: '#EAEDED',
    300: '#E4E4E7',
    400: '#E9E9E9',
    500: '#A1A1AA',
    600: '#71717A',
    700: '#616161',
    800: '#52525B',
    900: '#18181B',
  },
};
