// import { Advert as AdvertInterface } from '../../services/interfaces/Advert';
import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Category extends BaseEntity {
  protected API_SLUG: string = 'categories';

  async getOne(params?: any) {
    const { err, data } = await this.get(this.API_SLUG, params);
    if (err) return { err };
    return data;
  }

  async getList(filter: {}) {
    const { err, data } = await this.get(this.API_SLUG, filter);
    if (err) return { err };
    return data;
  }

  async create(params?: any): Promise<WebApiResponse<any>> {
    params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.post(this.API_SLUG, params);
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    if (err) return { err };

    return { data };
  }

  async removeOne(params?: any) {
    this.del(this.API_SLUG, {});
  }
}

export default Category;
