export function getFileTitle(filename: string, max: number): string {
  const name = filename.substring(0, filename.lastIndexOf('.'));
  const ext = filename.substring(filename.lastIndexOf('.') + 1);
  const titleArr: string[] = [];
  titleArr.push(name.length > max ? name.substring(0, max) + '... ' : name);
  titleArr.push(ext);
  const title = titleArr.length > 1 ?
    titleArr.join('.') :
    titleArr.join();
  return title;
}
