// chatQueries.ts
export const getPartnerQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getPartnerChat',
    baseModule: 'Chat',
    data: filter,
  }),
});
export const getUnReadMessagesQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getUnReadMessagesList',
    baseModule: 'Chat',
    data: filter,
  }),
});
export const delMessagesQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'deleteUnReadMessagesList',
    baseModule: 'Chat',
    data: filter,
  }),
});
export const removeChatUser = (biulder: any) => ({
  query: (filter: {}) => {
    return {
      functionName: 'removeChatUser',
      baseModule: 'Chat',
      data: filter,
    };
  },
});
export const addUserInChat = (biulder: any) => ({
  query: (filter: {}) => {
    return {
      functionName: 'createChatsUsers',
      baseModule: 'Chat',
      data: filter,
    };
  },
});
export const postMessage = (biulder: any) => ({
  query: (props: any) => ({
    functionName: 'createMessages',
    baseModule: 'Chat',
    data: props,
  }),
});
export const getMessages = (biulder: any) => ({
  query: (chatId: string) => ({
    functionName: 'getMessagesList',
    baseModule: 'Chat',
    data: chatId,
  }),
});
export const getChatAdvert = (biulder: any) => ({
  query: (leadId: string) => ({
    functionName: 'getChatAdvert',
    baseModule: 'Chat',
    data: leadId,
  }),
});
export const getRoleList = (biulder: any) => ({
  query: (data: any) => ({
    functionName: 'getRoleList',
    baseModule: 'Company',
    data,
  }),
});
export const getOrganizationByUser = (biulder: any) => ({
  query: (data: any) => ({
    functionName: 'getOne',
    baseModule: 'Company',
    data,
  }),
});
export const getChat = (biulder: any) => ({
  query: (chatId: string) => ({
    functionName: 'getChatsList',
    baseModule: 'Chat',
    data: chatId,
  }),
});
export const getChatUsers = (biulder: any) => ({
  query: (filter: {}) => {
    return {
      functionName: 'getChatUsers',
      baseModule: 'Chat',
      data: filter,
    };
  },
});
export const getUsers = (biulder: any) => ({
  query: (filter: {}) => {
    return {
      functionName: 'getList',
      baseModule: 'User',
      data: filter,
    };
  },
});
export const getLead = (biulder: any) => ({
  query: (id: number) => {
    return {
      functionName: 'getList',
      baseModule: 'Leads',
      data: id,
    };
  },
});
export const getLeadsList = (biulder: any) => ({
  query: (filter: {}) => {
    return {
      functionName: 'getList',
      baseModule: 'Leads',
      data: filter,
    };
  },
});
export const getChatsList = (biulder: any) => ({
  query: (data: any) => ({
    functionName: 'getAllChats',
    baseModule: 'Chat',
    data,
  }),
});
export const getAdvertCustomerChat = (biulder: any) => ({
  query: (data: any) => ({
    functionName: 'getAdvertCustomer',
    baseModule: 'Chat',
    data,
  }),
});
