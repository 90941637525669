import { WebApiResponse } from 'types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import WebApi from '..';
import { Doc } from 'services/interfaces/Doc';

class Docs extends BaseEntity {
  protected API_DOCCAT: string = 'doc-cats';
  protected API_DOCS: string = 'docs';

  async getListDocCat(filter: {}): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(this.API_DOCCAT, filter);
    if (err)
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    return data;
  }

  async createDocCat(filter: {}): Promise<WebApiResponse<any>> {
    const { err, data } = await this.post(this.API_DOCCAT, filter);
    if (err) {
      if ((err as any).response?.data?.error?.message === 'Already exists') {
        return { err: { message: 'Псевдоним не уникален!' } };
      }
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    }
    return { data };
  }

  async changeOneDocCat(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.put(this.API_DOCCAT, filter);
    if (err) {
      if ((err as any).response?.data?.error?.message === 'Already exists') {
        return { err: { message: 'Псевдоним не уникален!' } };
      }
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    }
    return { data };
  }

  async removeOneDocCat(params?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.API_DOCCAT, params);
    if (err)
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    return data;
  }

  async getListDocs(filter: {}): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(this.API_DOCS, filter);
    if (err)
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    return data;
  }

  async createDocs(filter: {}): Promise<WebApiResponse<any>> {
    const { err, data } = await this.post(this.API_DOCS, filter);
    if (err)
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    return data;
  }

  async changeOneDoc(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.put(this.API_DOCS, filter);
    if (err)
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    return data;
  }

  async removeOneDoc(params?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.API_DOCS, params);
    if (err)
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    return data;
  }

  async changeStatus({
    docId,
    docStatus,
  }: {
    docId: number;
    docStatus: 'draft' | 'public';
  }): Promise<WebApiResponse<Doc>> {
    const { docStatus: docStatusList } = await WebApi().Dictionary.getOne({
      dicts: 'docStatus',
    });
    const { id: statusId } = docStatusList.find(
      (item: any) => item.alias === docStatus
    );
    const { err, data } = await this.put(this.API_DOCS, {
      id: docId,
      docStatusId: statusId,
    });
    if (err)
      return {
        err: {
          message:
            (err as any).response?.data?.error?.message || 'Неизвестная ошибка',
        },
      };
    return { data };
  }
}

export default Docs;
