import { Lead, ApiError } from 'shared/types';
import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
// import { Company as CompanyType } from '../interfaces/Company';
import { isAxiosError } from 'axios';

export type LeadResponse<T extends Lead | Lead[]> = {
  data: T;
  error: ApiError;
};

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Leads extends BaseEntity {
  protected LEADS: string = 'leads';
  protected LEADSTATUS: string = 'lead-statuses';

  async getOne(guid: string) {
    console.log('get one lead', guid);
  }

  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.LEADS, filter);
    return data;
  }
  async getLeadStatus(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.LEADSTATUS, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return data;
  }
  async create<T extends Lead | Lead[]>(
    params: Partial<Lead>
  ): Promise<LeadResponse<T>> {
    const userId = (await this._getSession())?.user?.id;
    if (userId) {
      params.userId = userId;
    }
    const { err, data: respData } = await this.post(this.LEADS, params);
    const data: T = respData?.data?.data;
    const error: ApiError = (<any>err)?.response?.data?.error;
    return { data, error };
  }

  async update<T extends Lead | Lead[]>(
    params: Partial<Lead> & { id: number }
  ): Promise<LeadResponse<T>> {
    const userId = (await this._getSession())?.user?.id;
    if (userId) {
      params.userId = userId;
    }
    const { err, data: respData } = await this.put(this.LEADS, params);
    const data: T = respData?.data?.data;
    const error: ApiError = (<any>err)?.response?.data?.error;
    return { data, error };
  }

  async removeOne(params?: any) {
    this.del(this.LEADS, {});
  }
}

export default Leads;
