import BaseEntity from '../BaseEntity';
import { RequestConfig } from 'shared/types/WebApiTypes';
import {
  Audio,
  Image,
  Media,
  Video,
  ApiError,
  Document,
} from 'shared/types';
import {
  FileItem,
  FileSlug,
  FileType,
  FileCategory,
} from 'shared/constants';

export type GetParameters<T extends Partial<FileItem>> = Partial<Record<keyof T|string, T[keyof T]|T[keyof T][]>>;

export type FileResponse<R extends FileItem|FileItem[] = FileItem|FileItem[]> = {
  data: R;
  error: ApiError;
}

export type GetAllFilesResponse = {
  data: {[key in FileCategory]: Audio[]|Image[]|Media[]|Video[]|Document[]};
  error: ApiError;
}

class Files extends BaseEntity {

  async getFile<
    T extends FileItem = FileItem,
    R extends FileItem|FileItem[] = FileItem|FileItem[],
  >(
    slug: FileSlug,
    params: GetParameters<T>,
    config?: RequestConfig,
  ): Promise<FileResponse<R>>{
    const { err, data } = await this.get(slug, params, config);
    const error = (err as any)?.response.data.error || err;
    return { data, error };
  }

  async createFile<
    R extends FileItem|FileItem[] = FileItem|FileItem[],
  >(
    slug: FileSlug,
    params: FormData,
    config?: RequestConfig,
  ): Promise<FileResponse<R>> {
    const reqConfig = {contentType: 'multipart/form-data', ...config}
    const { err, data: respData } = await this.post(slug, params, reqConfig);
    const data: R = respData?.data?.data;
    const error = (err as any)?.response.data.error || err;
    return { data, error };
  }

  async updateFile<
    R extends FileItem|FileItem[] = FileItem|FileItem[],
  >(
    slug: FileSlug,
    params: FormData,
    config?: RequestConfig,
  ): Promise<FileResponse<R>> {
    const reqConfig = {contentType: 'multipart/form-data', ...config};
    const { err, data: respData } = await this.put(slug, params, reqConfig);
    const data: R = respData?.data?.data;
    const error = (err as any)?.response.data.error || err;
    return { data, error };
  }

  async delFile<
    R extends FileItem|FileItem[] = FileItem|FileItem[],
  >(
    slug: FileSlug,
    params: {ids: number[]},
    config?: RequestConfig,
  ): Promise<FileResponse<R>> {
    const { err, data: respData } = await this.del(slug, params, config);
    const data: R = respData?.data?.data;
    const error = (err as any)?.response.data.error || err;
    return { data, error };
  }

  async getAllFiles(
    params: Partial<FileItem> & {fileType: FileType},
    config?: RequestConfig,
  ): Promise<GetAllFilesResponse> {
    const { err, data } = await this.get('files', params, config);
    const error = (err as any)?.response.data.error || err;
    return { data, error };
  }
}

export default Files;
